import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'plants',
  formId: '/UK%20general%20plants',
  title: 'Planter',
  subTitle: 'Grønt er det nye sort',
  icon: '/icons/plants.svg',
  image: '/assets/images/sunrise/plants_supermercat.png',
  theme: 'sunrise-plants',
  sections: [
    {
      sectionId: 'hero',
      label: 'Planter',
      title: 'Grønt er det nye sort',
      description:
        'Planter ser dejlige ud og skaber en stemning af liv og vækst. De støvsuger også luften for de små, skadelige partikler, alt vores IT udstyr udspyr, producerer ilt til vore trætte hoveder og booster luftfugtigheden. Alt sammen ting, der reducerer sygefravær.',
      image: '/assets/images/sunrise/plants_supermercat.png',
      icon: '/icons/plants.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Stort eller småt. Grønt eller farvefest!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/plants/service_1.svg',
          title: 'Stueplanter',
          description: '',
        },
        {
          icon: '/assets/images/services/plants/service_2.svg',
          title: 'Planteservice',
          description: '',
        },
        {
          icon: '/assets/images/services/plants/service_3.svg',
          title: 'Blomster',
          description: '',
        },
        {
          icon: '/assets/images/services/plants/service_4.svg',
          title: 'Tørrede blomster',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Det er nemmere end du tror',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/plants/journey_1.svg',
          title: 'Hvad kan du lide?',
          description: 'Giv os en ide om, hvad der falder i smag, så vi kan sammensætte noget, der virker for jer.',
        },
        {
          icon: '/assets/images/services/plants/journey_2.svg',
          title: 'Få et tilbud',
          description: 'Vi laver et detaljeret tilbud over dine grønne ønsker',
        },
        {
          icon: '/assets/images/services/plants/journey_3.svg',
          title: 'Ja tak!',
          description: 'Det var det. Nu er der liv og farver på vej til jer.',
        },
        {
          icon: '/assets/images/services/plants/journey_4.svg',
          title: 'Velkommen i junglen',
          description: 'Aaah! Duften og synet af grønt og blomster. Er luften ikke blevet lidt bedre?',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor elsker kunder planter fra Good Monday?',
      description: '',
      image: '/assets/images/services/plants/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-droplet-outline',
          title: 'Gør det selv eller fuld service',
          description:
            'Du bestemmer selv, om I vil gå rundt med vandkanden, eller om vore partnere skal tage sig af det hele.',
        },
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration',
          description: 'Vi har et kæmpe katalog af grønt til enhver smag.',
        },
        {
          icon: 'eva eva-phone-call-outline',
          title: 'Rådgivning om plantevalg',
          description: 'Sol eller ej, gødning eller ej. Vi rådgiver om, hvor og hvordan, det bliver nemt for jer.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title:
        'Vidste du, at Good Monday ikke alene kan holde dine planter i live, men også få dem til at strutte af sundhed?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
